<template>
  <div class="content" style="padding-top: 80px">
    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <div class="media align-items-stretch justify-content-center ht-100p pos-relative">
          <div class="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div class="wd-100p">
              
              <p class="tx-color-03 tx-16 mg-b-40">Регистерийн дугаарын сүүлийн 4 орон оруулна уу.</p>

              <div class="form-group">
                <label>{{regNo}}
                  {{lastDigits==''?'_ _ _ _':lastDigits}}
                </label>
                <input type="tel" v-model="lastDigits" maxlength="4" class="form-control" placeholder="сүүлийн 4 оронг нөхөж бичнэ үү">
              </div>
              
              <button @click="checkRegister()" class="btn btn-brand-02 btn-block">Шалгах</button>

              <!-- <div class="divider-text">эсвэл</div>
              
              <button @click="notMyRegisterModal.modal= true" class="btn btn-outline-twitter btn-block">Таны регистр биш бол энд дарна уу</button> -->
              
            </div>
          </div><!-- sign-wrapper -->
        </div>
    </div>

    <div v-if="notMyRegisterModal.modal" class="modal fade show" style="display: block; padding-right: 17px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content tx-14">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">Мэдээллэх</h6>
            <button @click="notMyRegisterModal.modal=false" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="mg-b-0">Танд мессежээр ирсэн улсын дугаарын эхний 4 оронг бичнэ үү</p>
            <input v-model="notMyRegisterModal.plateNo" maxlength="4" class="form-control mg-y-20" type="tel" />
          </div>
          <div class="modal-footer">
            <button @click="notMyRegisterModal.modal=false" type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Болих</button>
            <button @click="sendNotMyRegister()" type="button" class="btn btn-primary tx-13">Илгээх</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="notMyRegisterModal.modal" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { request } from '@/api/all';
import { mapActions } from "vuex";
import { setToken } from "@/libs/util";


export default {
  name: 'Home',
  data(){
    return{
      regNo: '',
      lastDigits: '',
      notMyRegisterModal:{
        modal: false,
        plateNo: ''
      }
    }
  },
  mounted() {
    //console.log(this.$route.params.id);
    this.handleLoading({ isLoading: true });
    request({
      api: { method: 'GET', url: 'Auth/Pre'  },
      params:{id: this.$route.params.id},
      store: this.$store
    }).then(res => {
      this.handleLoading({ isLoading: false });
      if (res !== false) {
        this.regNo= res.data.regNo;
        //console.log(res);
      }
    });
  },
  methods:{
    ...mapActions(["handleLoading"]),
    checkRegister(){
      // TODO id reg 2 iig end shalgana
      let errs=[];
      if(this.lastDigits==''){
        errs.push('Утга хоосон байна');
      } else if(this.lastDigits.length!=4){
        errs.push('4 тоо оруулна уу');
      } 
      // else if(this.lastDigits!='0507'){
      //   errs.push('буруу утга оруулсан байна');
      // }

      if(errs.length==0){
        this.handleLoading({ isLoading: true });
        request({
          api: { method: 'POST', url: 'Auth/Check'  },
          data:{ id: this.$route.params.id, pass: this.lastDigits },
          store: this.$store
        }).then(res => {
          this.handleLoading({ isLoading: false });
          if (res !== false) {
            //console.log('Check', res);
           setToken(res.token);
           this.$router.push({name: 'penalty', params: {id: this.$route.params.id}}) 
          }
        });
      } else {
        alert(errs[0]);
      }
    },
    sendNotMyRegister(){
      this.handleLoading({ isLoading: true });
      request({
        api: { method: 'POST', url: 'Auth/NotMyRegno'  },
        data:{ id: this.$route.params.id, plateNo: this.notMyRegisterModal.plateNo },
        store: this.$store
      }).then(res => {
        this.handleLoading({ isLoading: false });
        if (res !== false) {
          this.notMyRegisterModal.modal= false;
        }
      });
    }
  }
}
</script>
