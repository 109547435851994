import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/:id',
    name: 'home',
    component: Home
  },
  {
    path: '/p/:id',
    name: 'penalty',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Detail.vue')
    }
  },
  {
    path: '/y/:id',
    name: 'pay',
    component: function () {
      return import(/* webpackChunkName: "pay" */ '../views/Pay.vue')
    }
  },
  {
    path: '/a/:id',
    name: 'regCheck',
    component: function () {
      return import(/* webpackChunkName: "pay" */ '../views/regNoCheck.vue')
    }
  },
  {
    path: '/l/:id',
    name: 'regList',
    component: function () {
      return import(/* webpackChunkName: "pay" */ '../views/regNoList.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  //mode: 'history',
  routes
})

export default router
