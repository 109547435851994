<template>
  <header class="navbar navbar-header navbar-header-fixed wd-100p" style="position: fixed; z-index: 99">
      <a id="mainMenuOpen" class="burger-menu">
        <!-- <img src="http://www.ubtraffic.mn/assets/img/icon.png" /> -->
      </a>
      <div class="navbar-brand">
        <a class="">Замын хөдөлгөөний удирдлагын төв<span></span></a>
      </div><!-- navbar-brand -->
      
      <div class="navbar-right">
        
      </div><!-- navbar-right -->
      <!-- navbar-search -->
    </header>
  <router-view />
  <div class="locker-loader" :style="{'display': $store.state.loading? 'block' : 'none'}">
    <div class="locker-main" style="min-width:140px;">Түр хүлээнэ үү...</div>
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.dynamic-app .locker-loader {
  display: none;
}
.dynamic-app.dynamic-locker .locker-loader {
  display: block;
}
.locker-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: hsla(0, 0%, 100%, 0.5) !important;
  backdrop-filter: blur(1px);
  z-index: 9999;
  top: 0;
  left: 0;
  text-align: center;
}
.locker-main {
  color: white;
  border-radius: 0 0 5px 5px;
  padding: 5px;
  width: 10vw;
  margin: 0 auto;
  background: orange;
}
</style>
